import React, { useMemo, useEffect } from 'react'
import { PageWrapper } from 'pages/styled'
import { AutoColumn } from 'components/Column'
import { TYPE, HideSmall } from 'theme'
import TokenTable from 'components/tokens/TokenTable'
import { useAllTokenData, useTokenDatas } from 'state/tokens/hooks'
import { notEmpty } from 'utils'
import { useSavedTokens } from 'state/user/hooks'
import { DarkGreyCard } from 'components/Card'
import TopTokenMovers from 'components/tokens/TopTokenMovers'

export default function TokensOverview() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const allTokens = useAllTokenData()

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allTokens])
  const formattedAllTokens = useMemo(() => {
    return formattedTokens?.filter((t) => t.name || t.symbol)
  }, [formattedTokens])

  const [savedTokens] = useSavedTokens()
  const watchListTokens = useTokenDatas(savedTokens)
  const formattedTokensWhenAddWatchList = useMemo(() => {
    return watchListTokens?.filter((t) => t.name || t.symbol)
  }, [watchListTokens])

  return (
    <PageWrapper>
      <AutoColumn gap="lg">
        <TYPE.responsiveMain fontSize={16}>Your Watchlist</TYPE.responsiveMain>
        {savedTokens.length > 0 ? (
          <TokenTable tokenDatas={formattedTokensWhenAddWatchList} />
        ) : (
          <DarkGreyCard>
            <TYPE.responsiveMain fontSize={16}>Saved tokens will appear here</TYPE.responsiveMain>
          </DarkGreyCard>
        )}
        <HideSmall>
          <DarkGreyCard style={{ paddingTop: '12px' }}>
            <AutoColumn gap="md">
              <TYPE.mediumHeader fontSize="16px">Top Movers</TYPE.mediumHeader>
              <TopTokenMovers />
            </AutoColumn>
          </DarkGreyCard>
        </HideSmall>
        <TYPE.responsiveMain fontSize={16}>All Tokens</TYPE.responsiveMain>
        <TokenTable tokenDatas={formattedAllTokens} />
      </AutoColumn>
    </PageWrapper>
  )
}
