import { NetworkInfo, XinfinNetworkInfo } from 'constants/networks'

export function networkPrefix(activeNewtork: NetworkInfo) {
  const isXinfin = activeNewtork === XinfinNetworkInfo
  if (isXinfin) {
    return '/'
  }
  const prefix = '/' + activeNewtork.route.toLocaleLowerCase() + '/'
  return prefix
}
