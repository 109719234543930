export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,
  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  CELO = 42220,

  ARBITRUM_GOERLI = 421613,

  OPTIMISM_GOERLI = 420,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
  CELO_ALFAJORES = 44787,
  BNB = 56,

  XDC = 50,
}
